import {
  accountsBaseUrl,
  onboardingBaseUrl,
  creatorAdminBaseUrl,
} from 'leap-auth';

import type { DataFunctionArgs } from '@remix-run/cloudflare';

export const createCourseUrl = (args: DataFunctionArgs) =>
  `${creatorAdminBaseUrl(args)}/storefront/course/new`;

export const createGuideUrl = (args: DataFunctionArgs) =>
  `${creatorAdminBaseUrl(args)}/storefront/guide/new`;

export const dashboardUrl = (args: DataFunctionArgs) =>
  `${creatorAdminBaseUrl(args)}/storefront`;

export const signUpUrl = (args: DataFunctionArgs) =>
  `${accountsBaseUrl(args)}/signup`;

export const onboardingTopicsPath = () => '/onboarding/topics';
export const onboardingExpertisePath = () => '/onboarding/expertise';
export const onboardingValueLadderPath = () => '/onboarding/value-ladder';
export const onboardingUrl = (args: DataFunctionArgs) =>
  `${onboardingBaseUrl(args)}/onboarding/expertise`;
